<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="debounceInput"
    :error-messages="valueErrors"
    @keydown.enter.prevent="submit()"
    required
    v-bind="$attrs"
    name="walletAdress"
    @mouseleave="$v.value.$touch()"
    @paste="$v.value.$touch()"
    id="form-input-walletAdress"
  />
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    value: {
      type: String,
    },
    formSubmited: {
      type: Boolean,
    },
  },
  watch: {
    formSubmited() {
      this.displayErrors = true;
    },
  },
  mixins: [validationMixin],
  methods: {
    submit() {
      this.$emit("submit-form");
    },
    debounceInput: _.debounce(function (e) {
      this.$emit("input", e);
    }, 1),
  },
  data() {
    return { displayErrors: false };
  },
  validations: {
    value: {
      required,
      async validWallet(val) {
        if (!val) return false;
        if (val === this.user.walletId) return false;
        const data = await this.$store.dispatch("wallet/getWalletById", val);
        return data?.result?.isValid;
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
    }),
    label() {
      return this.$t("dialog.recipientWallet");
    },
    valueErrors() {
      const errors = [];
      const validWallet = this.$v.value.validWallet;
      if (!this.$v.value.$dirty || !this.displayErrors) return errors;
      !this.$v.value.required && errors.push(this.$t("dialog.walletError1"));
      !validWallet && errors.push(this.$t("dialog.walletError2"));
      return errors;
    },
  },
};
</script>
